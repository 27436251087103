<template>
  <div class="d-page-wrapper">
    <div class="d-page-toolbar">
      <d-search-input
        v-model="search"
        @submit="onSearchSubmit"
      ></d-search-input>
      <v-btn
        v-text="'+ ' + $t('chain.createChain')"
        class="d-btn-1 ml-auto"
        depressed
        @click="openEditDialog(null)"
      ></v-btn>
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items="tableItems"
      :page="pageNumber"
      :items-per-page="pageSize"
      :server-items-length="totalCount"
      disable-sort
      hide-default-footer
      class="d-data-table d-data-table--rows-clickable"
      @page-count="pageCount = $event"
      @click:row="openEditDialog"
    >
      <template v-slot:[`item.dealers`]="{ item }">
        <span v-for="(dealerId, index) in item.dealers" :key="dealerId">{{ shortDealerById(dealerId).name }} <span v-if="index < item.dealers.length-1">, </span></span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="text-no-wrap d-visible-on-row-hover">
          <d-btn-icon type="edit" @click="openEditDialog(item)"></d-btn-icon>
          <d-btn-icon type="delete" @click="deleteChain_(item)"></d-btn-icon>
        </div>
      </template>
    </v-data-table>

    <v-pagination
      v-model="pageNumber"
      :length="pageCount"
      :total-visible="15"
      class="my-5"
      @input="onPageInput"
    ></v-pagination>

    <chain-edit-dialog
      :dialog.sync="editDialog"
      :chain="editDialog_chain"
    ></chain-edit-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { chainsPageSize } from '@/constants'

import pageMixin from '@/mixins/page.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import errorMixin from '@/mixins/error.mixin'

import DSearchInput from '@/components/DSearchInput'
import DBtnIcon from '@/components/DBtnIcon'
import ChainEditDialog from './ChainEditDialog'


export default {
  components: {
    DSearchInput,
    ChainEditDialog,
    DBtnIcon
  },


  mixins: [
    pageMixin,
    snackbarMixin,
    errorMixin
  ],


  data () {
    return {
      search: null,
      pageCount: 0,
      pageNumber: 1,
      pageSize: chainsPageSize,
      createDialog: false,
      editDialog: false,
      editDialog_chain: null
    }
  },


  computed: {
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('chain', ['tableItems', 'totalCount']),
    ...mapGetters('dealer', ['shortDealerById']),

    tableHeaders () {
      return [
        { text: this.$t('chain.table.name'), value: 'name' },
        { text: this.$t('chain.table.dealers'), value: 'dealers' },
        { text: '', value: 'actions', align: 'end', width: 1, sortable: false, filterable: false }
      ]
    }
  },


  methods: {
    ...mapActions('chain', ['loadChainsTableItems', 'loadChains', 'restoreInitialChainsTableItems', 'deleteChain']),


    openCreateDialog () {
      this.createDialog = true
    },

    openEditDialog (chain) {
      this.editDialog_chain = chain
      this.editDialog = true
    },

    onSearchSubmit () {
      this.reloadTableItems(1)
    },

    onPageInput () {
      this.reloadTableItems()
    },

    async reloadTableItems (page, isInitial = false) {
      try {
        this.startPageLoading()

        if (page && page !== this.pageNumber) {
          this.pageNumber = page
        }

        const items = await this.loadChainsTableItems({
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          search: this.search,
          isInitial
        })

        return items
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      } finally {
        this.stopPageLoading()
      }
    },

    async deleteChain_ (chain) {
      try {
        const confirm = await this.$confirm(this.$t('chain.deleteChainConfirm', chain), { trueText: this.$t('delete') })

        if (confirm === true) {
          this.showSnackbarProcessing()
          await this.deleteChain(chain.id)
          this.showSnackbarSuccess(this.$t('chain.chainDeleted'))

          if (!this.tableItems.length) {
            const page = (this.pageNumber - 1) || 1
            this.reloadTableItems(page)
          }
        }
      } catch (error) {
        if (this.is405NotAllowedError(error)) {
          this.hideSnackbar()
          await this.$alert(this.$t('chain.cannotDeleteWithRelations', chain), { title: this.$t('failed') })
        } else {
          this.showSnackbarError()
        }
        this.$log.error(error)
      }
    }
  },


  mounted () {
    this.restoreInitialChainsTableItems()
  },


  async created () {
    try {
      this.setPageTitle(this.$t('nav.chains'))
      this.startPageLoading()

      await this.loadChainsTableItems({
        search: this.search,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        isInitial: true
      })

      await this.loadChains()
    } catch (error) {
      this.showSnackbarError()
      this.$log.error(error)
    } finally {
      this.stopPageLoading()
    }
  }
}
</script>
