<template>
  <v-dialog
    v-if="editedChain"
    :value="dialog"
    :persistent="isModelChanged()"
    :max-width="450"
    content-class="d-dialog"
    scrollable
    @keydown.esc="escape(closeDialog)"
    @click:outside="escape(closeDialog)"
  >
    <v-card outlined class="d-dialog-card">
      <v-card-title>
        {{ chain ? $t('chain.editChain') : $t('chain.createChain') }}
        <d-btn-icon
          type="close"
          btn-class="d-dialog-btn-close"
          tabindex="4"
          @click="escape(closeDialog)"
        ></d-btn-icon>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" @submit.stop.prevent>
          <v-text-field
            v-model.trim="editedChain.name"
            :rules="rules.name"
            :label="$t('chain.edit.name')"
            tabindex="1"
            clearable
            autofocus
          ></v-text-field>
          <v-select
            v-model="editedChain.dealers"
            :label="$t('chain.edit.dealers')"
            :items="shortDealersSelectItems"
            tabindex="1"
            autofocus
            multiple
            chips
          ></v-select>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          v-text="$t('cancel')"
          color="secondary"
          text
          tabindex="3"
          @click="closeDialog"
        ></v-btn>
        <v-btn
          v-text="$t('save')"
          :disabled="!isModelChanged()"
          color="primary"
          text
          tabindex="2"
          @click="submit"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import { chainSchema } from '@/constants'

import objectUtil from '@/utils/object.util'

import dialogMixin from '@/mixins/dialog.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import validationMixin from '@/mixins/validation.mixin'
import unsavedChangesMixin from '@/mixins/unsavedChanges.mixin'
import errorMixin from '@/mixins/error.mixin'

import DBtnIcon from '@/components/DBtnIcon'


export default {
  props: {
    chain: Object
  },


  components: {
    DBtnIcon
  },


  mixins: [
    dialogMixin,
    snackbarMixin,
    validationMixin,
    unsavedChangesMixin,
    errorMixin
  ],


  data () {
    return {
      editedChain: null,
      defaultChain: {
        name: '',
        dealers: []
      },
      existingNames: []
    }
  },

  computed: {
    ...mapGetters('dealer', ['shortDealers']),
    ...mapGetters('chain', ['chainById', 'chains']),

    shortDealersSelectItems () {
      return this.shortDealers.map(dealer => {
        let text = dealer.name
        if (dealer.chainId) {
          const chainName = this.chainById(dealer.chainId)?.name
          text += ` (${chainName})`
        }
        return {
          text,
          value: dealer.id,
          disabled: dealer.chainId && dealer.chainId !== this.editedChain.id
        }
      })
    },

    rulesToApply () {
      const rule = this.rule

      return {
        name: [
          rule.required(),
          rule.maxLength(chainSchema.nameMaxLength),
          rule.unique(this.existingNames, this.$t('chain.edit.nameExists'))
        ]
      }
    }
  },


  methods: {
    ...mapActions('chain', ['createChain', 'updateChain']),
    ...mapActions('dealer', ['loadShortDealers']),

    onDialogOpen () {
      this.editedChain = objectUtil.getObjectCopy(this.chain || this.defaultChain)

      this.initWatchedModel(this.editedChain)
      this.resetValidation()

      this.existingNames = []
    },

    async submit () {
      const valid = await this.validate(this.rulesToApply)
      if (!valid || !this.isModelChanged()) return

      try {
        this.showSnackbarProcessing()
        const chain = objectUtil.getObjectCopy(this.editedChain)

        if (chain.id) {
          await this.updateChain(chain)
          this.showSnackbarSuccess(this.$t('chain.chainUpdated'))
        } else {
          await this.createChain(chain)
          this.showSnackbarSuccess(this.$t('chain.chainCreated'))
        }
        this.loadShortDealers()
        this.closeDialog()
      } catch (error) {
        if (this.is409ConflictError(error)) {
          this.hideSnackbar()
          this.existingNames.push(this.editedChain.name)
          this.validate(this.rulesToApply)
        } else {
          this.showSnackbarError()
        }
        this.$log.error(error)
      }
    }
  }
}
</script>
